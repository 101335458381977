import axiosInstance from '@app/utils/axiosInstance';
import {
  SharedSecurityDetailsResponse,
  SharedSwitchAdjustment,
  SharedSwitchManualAdjustment,
  SharedSwitchManualAdjustmentType,
} from '@bsa/shared-types';

export const failedToApplySwitchManualAdjustmentsMessage =
  'Failed to apply manual adjustments';

export async function switchManualAdjustments(
  type: SharedSwitchManualAdjustmentType,
  value: string,
  proposal: SharedSwitchAdjustment,
  security: SharedSecurityDetailsResponse,
  setErrorMessage?: (message: string) => void,
): Promise<SharedSwitchAdjustment> {
  const body: SharedSwitchManualAdjustment = {
    type,
    value,
    proposal,
    security,
  };

  try {
    const response = await axiosInstance.post(
      '/manual-adjustments/switch/adjust',
      body,
    );
    return response.data;
  } catch {
    if (setErrorMessage) {
      setErrorMessage(failedToApplySwitchManualAdjustmentsMessage);
    }
    throw new Error(failedToApplySwitchManualAdjustmentsMessage);
  }
}
