import {
  IressButton,
  IressIcon,
  IressInline,
  IressStack,
} from '@iress/components-react';

function ButtonLinkWithIcon(
  props: Readonly<{
    onClick: () => void;
    dataTestId: string;
    iconName: string;
    linkText: string;
  }>,
) {
  return (
    <IressStack gutter={IressStack.Gutter.Md}>
      <IressButton
        mode={IressButton.Mode.Link}
        type={IressButton.Type.Button}
        data-testid={props.dataTestId}
        onClick={props.onClick}
      >
        <IressInline
          gutter={IressInline.Gutter.Sm}
          verticalAlign={IressInline.VerticalAlign.Middle}
        >
          <IressIcon name={props.iconName} />
          {props.linkText}
        </IressInline>
      </IressButton>
    </IressStack>
  );
}

export default ButtonLinkWithIcon;
