import { calculateTargetNativeValue } from '@app/components/ProposalsGrid/ProposalsGrid.utils';
import { assignZeroIfNullOrUndefined } from '@app/utils';
import { ZERO } from '@app/utils/constants';
import {
  AccountGroupAdjustment,
  AccountGroupLevelResponse,
  AccountLevelResponse,
  Adjustment,
  SharedSecurityDetailsResponse,
  SharedSwitchAdjustment,
  SharedSwitchType,
} from '@bsa/shared-types';
import { IRowNode } from 'ag-grid-community';
import Decimal from 'decimal.js';

export const createAccountRowData = (
  accountProposal: AccountLevelResponse,
  security: SharedSecurityDetailsResponse,
): Adjustment => {
  const targetNativeValue = calculateTargetNativeValue(
    accountProposal.units,
    security.nativePrice,
  );
  return {
    accountGroup: accountProposal.accountGroupName,
    accountGroupCode: accountProposal.accountGroupCode,
    portfolio: accountProposal.portfolio,
    account: accountProposal.account,
    externalAccountId: accountProposal.externalAccountId ?? '',
    instrument: security.description,
    SEDOL: security.sedol,
    ISIN: security.isin,
    security: `${security.code}.${security.exchange}`,
    'marketPrice (CCY)': assignZeroIfNullOrUndefined(security.marketPrice),
    nativePrice: assignZeroIfNullOrUndefined(security.nativePrice),
    nativeCurrency: security.nativeCurrency,
    assetClassShortName: security.assetClass,
    'currentValue (CCY)': assignZeroIfNullOrUndefined(
      accountProposal.holdingValue,
    ),
    'targetValue (CCY)': assignZeroIfNullOrUndefined(
      accountProposal.holdingValue,
    ),
    'proposedValue (CCY)': '0',
    targetNativeValue: assignZeroIfNullOrUndefined(targetNativeValue),
    proposedNativeValue: '0',
    currentUnits: assignZeroIfNullOrUndefined(accountProposal.units),
    targetUnits: assignZeroIfNullOrUndefined(accountProposal.units),
    proposedUnits: '0',
    currentWeight: assignZeroIfNullOrUndefined(accountProposal.weight),
    targetWeight: assignZeroIfNullOrUndefined(accountProposal.weight),
    proposedWeight: '0',
    'currentCashAvailable (CCY)': assignZeroIfNullOrUndefined(
      accountProposal.cashAmount,
    ),
    'proposedCashAvailable (CCY)': assignZeroIfNullOrUndefined(
      accountProposal.cashAmount,
    ),
    'currentCash %': assignZeroIfNullOrUndefined(
      accountProposal.cashPercentage,
    ),
    'assetClass %': assignZeroIfNullOrUndefined(
      accountProposal.assetClassPercentage,
    ),
    taxType: accountProposal.taxType,
    'taxableGain/Loss (CCY)': assignZeroIfNullOrUndefined(
      accountProposal.taxableGainLoss,
    ),
    'gain/Loss (CCY)': assignZeroIfNullOrUndefined(accountProposal.gainLoss),
    status: accountProposal.status,
    accountId: accountProposal.accountId?.toString(),
    portfolioValue: assignZeroIfNullOrUndefined(accountProposal.value),
    serviceType: accountProposal.serviceType,
    hierarchy: [accountProposal.accountGroupName, accountProposal.account],
    allowPartialVolume: security.allowPartialVolume,
  };
};
export const createAccountGroupRowData = (
  proposal: AccountGroupLevelResponse,
  security: SharedSecurityDetailsResponse,
): Adjustment => {
  const targetNativeValue = calculateTargetNativeValue(
    proposal.units,
    security.nativePrice,
  );

  return {
    accountGroup: proposal.accountGroupName,
    accountGroupCode: proposal.accountGroupCode,
    portfolio: proposal.portfolio,
    account: '',
    externalAccountId: '',
    instrument: security.description,
    SEDOL: security.sedol,
    ISIN: security.isin,
    security: `${security.code}.${security.exchange}`,
    'marketPrice (CCY)': assignZeroIfNullOrUndefined(security.marketPrice),
    nativePrice: assignZeroIfNullOrUndefined(security.nativePrice),
    nativeCurrency: security.nativeCurrency,
    assetClassShortName: security.assetClass,
    'currentValue (CCY)': assignZeroIfNullOrUndefined(proposal.holdingValue),
    'targetValue (CCY)': assignZeroIfNullOrUndefined(proposal.holdingValue),
    'proposedValue (CCY)': '0',
    targetNativeValue: assignZeroIfNullOrUndefined(targetNativeValue),
    proposedNativeValue: '0',
    currentUnits: assignZeroIfNullOrUndefined(proposal.units),
    targetUnits: assignZeroIfNullOrUndefined(proposal.units),
    proposedUnits: '0',
    currentWeight: assignZeroIfNullOrUndefined(proposal.weight),
    targetWeight: assignZeroIfNullOrUndefined(proposal.weight),
    proposedWeight: '0',
    'currentCashAvailable (CCY)': assignZeroIfNullOrUndefined(
      proposal.cashAmount,
    ),
    'proposedCashAvailable (CCY)': assignZeroIfNullOrUndefined(
      proposal.cashAmount,
    ),
    'currentCash %': assignZeroIfNullOrUndefined(proposal.cashPercentage),
    'assetClass %': assignZeroIfNullOrUndefined(proposal.assetClassPercentage),
    taxType: '',
    'taxableGain/Loss (CCY)': '0',
    'gain/Loss (CCY)': '0',
    status: '',
    accountId: '',
    portfolioValue: assignZeroIfNullOrUndefined(proposal.value),
    serviceType: proposal.serviceType ?? '',
    hierarchy: [proposal.accountGroupName],
    allowPartialVolume: security.allowPartialVolume,
  };
};

export const createAccountsInstructionsRowData = (
  selectedPositions: (AccountGroupLevelResponse | AccountLevelResponse)[],
  security: SharedSecurityDetailsResponse,
): Adjustment[] => {
  return (selectedPositions as AccountLevelResponse[]).map((position) =>
    createAccountRowData(position, security),
  );
};

export const createGroupAdjustments = (
  selectedPositions: AccountGroupLevelResponse[],
  security: SharedSecurityDetailsResponse,
) => {
  const accountGroupProposals: AccountGroupAdjustment[] = [];
  for (const proposal of selectedPositions) {
    const accountGroup = createAccountGroupRowData(proposal, security);
    const accounts: Adjustment[] = [];

    for (const accountProposal of proposal.accounts) {
      accounts.push(createAccountRowData(accountProposal, security));
    }

    accountGroupProposals.push({
      accountGroup: accountGroup,
      accounts: accounts,
    });
  }
  return accountGroupProposals;
};
export const createGroupsInstructionsRowData = (
  selectedPositions: AccountGroupAdjustment[],
): Adjustment[] => {
  return selectedPositions.flatMap((accountGroupProposal) => {
    return [
      accountGroupProposal.accountGroup,
      ...accountGroupProposal.accounts,
    ];
  });
};

export const createAccountSwitchesRowData = (
  selectedPositions: AccountLevelResponse[],
  selectedSecurity: SharedSecurityDetailsResponse,
): SharedSwitchAdjustment[] => {
  const switches: SharedSwitchAdjustment[] = [];
  for (const position of selectedPositions) {
    switches.push(
      {
        accountId: position.accountId.toString(),
        switchType: SharedSwitchType.UNKNOWN,
        securityId: '',
        security: '',
        account: position.account,
        portfolio: position.portfolio,
        'currentValue (CCY)': '',
        'targetValue (CCY)': '',
        'proposedValue (CCY)': '',
        proposedUnits: '',
        currentUnits: '',
        hierarchy: [position.account],
      },
      {
        accountId: position.accountId.toString(),
        switchType: SharedSwitchType.SELL,
        securityId: selectedSecurity.securityId.toString(),
        security: selectedSecurity.description,
        account: position.account,
        portfolio: position.portfolio,
        'currentValue (CCY)': assignZeroIfNullOrUndefined(
          position.holdingValue,
        ),
        'targetValue (CCY)': assignZeroIfNullOrUndefined(position.holdingValue),
        'proposedValue (CCY)': '0',
        proposedUnits: '0',
        currentUnits: assignZeroIfNullOrUndefined(position.units),
        hierarchy: [position.account, selectedSecurity.description],
      },
    );
  }
  return switches;
};

export function checkRowsForSwitch(node: IRowNode<AccountLevelResponse>) {
  const holdingValue = new Decimal(
    assignZeroIfNullOrUndefined(node.data?.holdingValue),
  );
  return node.isSelected() && holdingValue.greaterThan(ZERO);
}
