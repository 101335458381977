import { getSelectedSwitchesRowIds } from '@app/utils/modifiers.utils';
import {
  SharedProposalResponse,
  SharedSecurityDetailsResponse,
  SharedSwitchAdjustment,
  SharedSwitchSecurity,
} from '@bsa/shared-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createSwitchInstructionThunk,
  createSwitchProposalThunk,
  fetchSwitchToSecurityMarketPrice,
  switchManualAdjustmentThunk,
} from './switchThunk';
import { ThunkLoadingStatus, LoadingStatus } from '@app/types/loadingStatus';
import {
  initialiseSwitchToSecurities,
  setSwitchToSecurityHelper,
  updateProposalHelper,
} from './switchSlice.helper';

export interface SwitchSecurityState extends SharedSwitchSecurity {
  status: LoadingStatus;
  message: string;
  id: string;
}

export interface UpdateSwitchToSecurityPayload {
  index: number;
  updatedSwitchToSecurity: SharedSecurityDetailsResponse;
}

export interface UpdateSwitchToSecurityPercentage {
  index: number;
  percentage: string;
}
export interface SwitchState {
  applySwitchesLoadingStatus: ThunkLoadingStatus;
  generateSwitchProposalLoadingStatus: ThunkLoadingStatus;
  switchManualAdjustmentsLoadingStatus: ThunkLoadingStatus;
  inputtedCurrentHolding: string;
  originalProposals: SharedSwitchAdjustment[];
  selectedSwitches: string[];
  switchProposalResponses: SharedProposalResponse;
  switchProposals: SharedSwitchAdjustment[];
  switchToSecurities: SwitchSecurityState[];
  isAdditionalSwitchVisible: boolean;
}

export const initialSwitchToSecurityState: SwitchSecurityState = {
  securityDetails: {
    securityId: 0,
    marketPrice: '0',
    userCurrency: '',
    code: '',
    exchange: '',
    description: '',
    sedol: '',
    isin: '',
    nativeCurrency: '',
    nativePrice: '',
    assetClass: '',
    searchType: '' as SharedSecurityDetailsResponse['searchType'],
    allowPartialVolume: false,
  },
  status: 'idle',
  message: '',
  percentage: '0',
  id: '0',
};

export const initialSwitchState: SwitchState = {
  applySwitchesLoadingStatus: {
    loading: 'idle',
    message: '',
  },
  generateSwitchProposalLoadingStatus: {
    loading: 'idle',
    message: '',
  },
  switchManualAdjustmentsLoadingStatus: {
    loading: 'idle',
    message: '',
  },
  inputtedCurrentHolding: '0',
  originalProposals: [],
  selectedSwitches: [],
  switchProposalResponses: { proposals: [], failedProposals: [] },
  switchProposals: [],
  switchToSecurities: [],
  isAdditionalSwitchVisible: true,
};

export const SwitchSlice = createSlice({
  name: 'Switch',
  initialState: initialSwitchState,
  reducers: {
    setSwitchState: (state, action: PayloadAction<SwitchState>) => {
      Object.assign(state, action.payload);
    },
    resetSwitchState: (state) => {
      Object.assign(state, initialSwitchState);
    },
    resetSwitchInstructionsForm: (state) => {
      state.inputtedCurrentHolding = initialSwitchState.inputtedCurrentHolding;
      state.switchToSecurities = initialSwitchState.switchToSecurities;
      state.isAdditionalSwitchVisible =
        initialSwitchState.isAdditionalSwitchVisible;
    },
    setInputtedCurrentHolding: (
      state,
      action: PayloadAction<SwitchState['inputtedCurrentHolding']>,
    ) => {
      state.inputtedCurrentHolding = action.payload;
    },
    setOriginalProposals: (
      state,
      action: PayloadAction<SwitchState['originalProposals']>,
    ) => {
      state.originalProposals = action.payload;
    },
    setSwitchProposals: (
      state,
      action: PayloadAction<SwitchState['switchProposals']>,
    ) => {
      state.switchProposals = action.payload;
    },
    setSelectedSwitches: (
      state,
      action: PayloadAction<SwitchState['switchProposals']>,
    ) => {
      state.selectedSwitches = getSelectedSwitchesRowIds(action.payload);
    },
    setSwitchToSecurities: (
      state,
      action: PayloadAction<SwitchState['switchToSecurities']>,
    ) => {
      state.switchToSecurities = action.payload.map((security, index) => ({
        ...security,
        id: security.securityDetails.securityId?.toString() || index.toString(), // Ensure unique key
      }));
    },
    setIsAdditionalSwitchVisible: (
      state,
      action: PayloadAction<SwitchState['isAdditionalSwitchVisible']>,
    ) => {
      state.isAdditionalSwitchVisible = action.payload;
    },
    setSwitchToSecurityPercentage: (
      state,
      action: PayloadAction<UpdateSwitchToSecurityPercentage>,
    ) => {
      const { index, percentage } = action.payload;
      initialiseSwitchToSecurities(state.switchToSecurities, index);
      state.switchToSecurities = state.switchToSecurities.map((security, i) =>
        i === index ? { ...security, percentage } : security,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSwitchInstructionThunk.pending, (state) => {
        state.applySwitchesLoadingStatus.loading = 'pending';
      })
      .addCase(createSwitchInstructionThunk.fulfilled, (state, action) => {
        state.applySwitchesLoadingStatus.loading = 'succeeded';
        state.switchProposals = action.payload.proposals;
        state.selectedSwitches = getSelectedSwitchesRowIds(
          action.payload.proposals,
        );
      })
      .addCase(createSwitchInstructionThunk.rejected, (state) => {
        state.applySwitchesLoadingStatus.loading = 'failed';
      })
      .addCase(createSwitchProposalThunk.pending, (state) => {
        state.generateSwitchProposalLoadingStatus.loading = 'pending';
      })
      .addCase(createSwitchProposalThunk.fulfilled, (state, action) => {
        state.generateSwitchProposalLoadingStatus.loading = 'succeeded';
        state.switchProposalResponses = action.payload;
      })
      .addCase(createSwitchProposalThunk.rejected, (state) => {
        state.generateSwitchProposalLoadingStatus.loading = 'failed';
      })
      .addCase(
        fetchSwitchToSecurityMarketPrice.fulfilled,
        (state, action: PayloadAction<UpdateSwitchToSecurityPayload>) => {
          const { index, updatedSwitchToSecurity } = action.payload;
          initialiseSwitchToSecurities(state.switchToSecurities, index);
          state.switchToSecurities = setSwitchToSecurityHelper(
            index,
            state.switchToSecurities,
            updatedSwitchToSecurity,
          );
        },
      )
      .addCase(fetchSwitchToSecurityMarketPrice.rejected, (state, action) => {
        const { index } = action.meta.arg;
        state.switchToSecurities = state.switchToSecurities.map(
          (security, securityIndex) =>
            securityIndex === index
              ? {
                  ...security,
                  status: 'failed',
                  message:
                    'Failed to fetch market price & user currency for security',
                }
              : security,
        );
      })
      .addCase(fetchSwitchToSecurityMarketPrice.pending, (state, action) => {
        const { index } = action.meta.arg;
        state.switchToSecurities = state.switchToSecurities.map(
          (security, securityIndex) =>
            securityIndex === index
              ? {
                  ...security,
                  status: 'pending',
                  message: 'Loading market price & user currency for security',
                }
              : security,
        );
      })
      .addCase(switchManualAdjustmentThunk.fulfilled, (state, action) => {
        state.switchManualAdjustmentsLoadingStatus.loading = 'succeeded';
        state.switchProposals = updateProposalHelper(state, action);
      })
      .addCase(switchManualAdjustmentThunk.rejected, (state) => {
        state.switchManualAdjustmentsLoadingStatus.loading = 'failed';
      })
      .addCase(switchManualAdjustmentThunk.pending, (state) => {
        state.switchManualAdjustmentsLoadingStatus.loading = 'pending';
      });
  },
});

export const {
  resetSwitchState,
  setInputtedCurrentHolding,
  setOriginalProposals,
  setSelectedSwitches,
  setSwitchProposals,
  setSwitchState,
  setSwitchToSecurities,
  setIsAdditionalSwitchVisible,
  resetSwitchInstructionsForm,
  setSwitchToSecurityPercentage,
} = SwitchSlice.actions;
export default SwitchSlice.reducer;
