import React from 'react';
import { ButtonSecondaryWithOnClick, ButtonPrimary } from '../Button';
import { Modal } from '@app/components/Modal';
import { showModal } from '@iress/components-react';

interface ApplySwitchAllocationModalProps {
  handleFormOnSubmitEvent: () => void;
}

export const applySwitchAllocationModalId = 'modal-applySwitchAllocation';

const ApplySwitchAllocationModal: React.FC<ApplySwitchAllocationModalProps> = ({
  handleFormOnSubmitEvent,
}) => {
  const applySwitchModalButtons = [
    <ButtonSecondaryWithOnClick
      key="Cancel"
      buttonText="Cancel"
      dataTestId="switch-new-btn-cancel"
      onClick={() => showModal(applySwitchAllocationModalId, false)}
    />,
    <ButtonPrimary
      key="Continue"
      buttonText="Continue"
      dataTestid="switch-new-btn-continue-new"
      onClick={handleFormOnSubmitEvent}
      link={''}
    />,
  ];

  return (
    <Modal
      id={applySwitchAllocationModalId}
      dataTestId={applySwitchAllocationModalId}
      title="Less than 100% allocated"
      message="You've allocated less than 100% to the securities you're switching to. Do you want to continue?"
      buttons={applySwitchModalButtons}
    />
  );
};

export default ApplySwitchAllocationModal;
