import { useCallback, useEffect, useRef, useState } from 'react';
import { fetchFormattingSettings } from '@app/features/Sysadmin';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  HeadingLevel,
  IressButton,
  IressPanel,
  showModal,
  toast,
} from '@iress/components-react';
import { useAppDispatch, useAppSelector } from './hooks';
import { NoActionsPage } from '@app/pages';
import { useFlag } from '@unleash/proxy-client-react';
import { UNLEASH_TOGGLES } from './unleash';
import { Modal } from '@app/components/Modal';
import {
  checkDataHandler,
  clickOutsideHandler,
  deleteGroupsItemsFromWindow,
  leaveBsaHandler,
  loadSavedProgress,
  resetState,
  returnFromSwitchPage,
  startNewSession,
} from './App.handlers';
import { setCurrentPage } from '@app/features/Page';
import {
  deleteSavedProgressThunk,
  fetchSavedProgress,
  saveProgressThunk,
} from '@app/features/SavedProgress/savedProgressThunk';
import { RootState } from './store';
import { fetchTableConfigsThunk } from '@app/features/TableConfig';
import { Spinner } from '@app/components/Spinner';
import './App.css';
import {
  ButtonPrimary,
  ButtonSecondaryWithOnClick,
} from '@app/components/Button';
export const SAVE_SESSION_MODAL_ID = 'save-session-modal';
export const EXIT_DELETE_CONFIRM_MODAL_ID = 'exit-delete-confirm-modal';
export const ENTRY_DELETE_CONFIRM_MODAL_ID = 'entry-delete-confirm-modal';
export const CONTINUE_SESSION_MODAL_ID = 'modal-continue-session';
export const RETURN_MODAL_ID = 'modal-return';

export function useApp(
  bsaRef: React.RefObject<HTMLDivElement>,
  isSaveProgressEnabled: boolean,
  exitModalId: string,
  exitDeleteConfirmModalId: string,
  continueSessionModalId: string,
  entryDeleteConfirmModalId: string,
  returnModalId: string,
  setShowModal: typeof showModal,
  setShowMainPage: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const saveProgressData = useAppSelector((state) => state.savedProgress.data);
  const saveProgressLoadingStatus = useAppSelector(
    (state) => state.savedProgress.loading,
  );
  const groups = useAppSelector((state) => state.accountGroups.dataItems);
  const PORTFOLIO_BULK_SECURITY_ACTIONS_DATA =
    window.PORTFOLIO_BULK_SECURITY_ACTIONS_DATA?.data;

  const checkData = useCallback(() => {
    if (saveProgressLoadingStatus === 'pending') return;
    checkDataHandler(
      saveProgressData as unknown as RootState,
      dispatch,
      navigate,
      continueSessionModalId,
      setShowModal,
      setShowMainPage,
    );
  }, [
    continueSessionModalId,
    dispatch,
    navigate,
    saveProgressData,
    saveProgressLoadingStatus,
    setShowModal,
    setShowMainPage,
  ]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) =>
      clickOutsideHandler(event, bsaRef, exitModalId, setShowModal),
    [bsaRef, exitModalId, setShowModal],
  );

  const showNoActionsPage = useCallback(
    () => groups.length <= 0 && isSaveProgressEnabled,
    [groups, isSaveProgressEnabled],
  );
  const handleExitModalSaveClick = useCallback(() => {
    void dispatch(saveProgressThunk()).then(() => {
      toast.success({
        headingText: 'Session saved',
        headingLevel: HeadingLevel.H3,
      });
      leaveBsaHandler(2000);
    });
    setShowModal(exitModalId, false);
  }, [setShowModal, exitModalId, dispatch]);

  const handleExitModalDeleteOkClick = useCallback(() => {
    void dispatch(deleteSavedProgressThunk()).then(() => {
      toast.success({
        headingText: 'Session deleted',
        headingLevel: HeadingLevel.H3,
      });
      leaveBsaHandler(2000);
    });
    setShowModal(exitDeleteConfirmModalId, false);
  }, [setShowModal, exitDeleteConfirmModalId, dispatch]);

  const handleExitModalDeleteClick = useCallback(() => {
    setShowModal(exitModalId, false);
    setShowModal(exitDeleteConfirmModalId, true);
  }, [exitDeleteConfirmModalId, exitModalId, setShowModal]);

  const handleExitModalDeleteCancelClick = useCallback(() => {
    setShowModal(exitDeleteConfirmModalId, false);
  }, [exitDeleteConfirmModalId, setShowModal]);

  const handleSessionModalContinueClick = useCallback(() => {
    loadSavedProgress(
      saveProgressData as unknown as RootState,
      dispatch,
      navigate,
    );
    setShowModal(continueSessionModalId, false);
    setShowMainPage(true);
  }, [
    continueSessionModalId,
    dispatch,
    navigate,
    saveProgressData,
    setShowModal,
    setShowMainPage,
  ]);

  const handleSessionModalDeleteOkClick = useCallback(() => {
    if (PORTFOLIO_BULK_SECURITY_ACTIONS_DATA)
      startNewSession(PORTFOLIO_BULK_SECURITY_ACTIONS_DATA, dispatch);
    setShowModal(continueSessionModalId, false);
    setShowModal(entryDeleteConfirmModalId, false);
    setShowMainPage(true);
    void dispatch(deleteSavedProgressThunk());
    toast.success({
      headingText: 'Previous session deleted',
      headingLevel: HeadingLevel.H3,
    });
  }, [
    PORTFOLIO_BULK_SECURITY_ACTIONS_DATA,
    continueSessionModalId,
    entryDeleteConfirmModalId,
    dispatch,
    setShowModal,
    setShowMainPage,
  ]);

  const handleSessionModalDeleteClick = useCallback(() => {
    setShowModal(entryDeleteConfirmModalId, true);
  }, [entryDeleteConfirmModalId, setShowModal]);

  const handleSessionModalDeleteCancelClick = useCallback(() => {
    setShowModal(entryDeleteConfirmModalId, false);
  }, [entryDeleteConfirmModalId, setShowModal]);

  const handlePreviousStepClick = useCallback(() => {
    setShowModal(returnModalId, true);
  }, [returnModalId, setShowModal]);

  const handlePreviousStepCancelClick = useCallback(() => {
    setShowModal(returnModalId, false);
  }, [returnModalId, setShowModal]);

  const handlePreviousStepReturnClick = useCallback(() => {
    setShowModal(returnModalId, false);
  }, [returnModalId, setShowModal]);

  const handleReturnModalReturnClick = useCallback(
    () => returnFromSwitchPage(dispatch, returnModalId, setShowModal),
    [dispatch, returnModalId, setShowModal],
  );
  return {
    checkData,
    handleClickOutside,
    showNoActionsPage,
    handleExitModalSaveClick,
    handleExitModalDeleteClick,
    handleExitModalDeleteOkClick,
    handleExitModalDeleteCancelClick,
    handleSessionModalContinueClick,
    handleSessionModalDeleteClick,
    handleSessionModalDeleteOkClick,
    handleSessionModalDeleteCancelClick,
    handlePreviousStepClick,
    handlePreviousStepCancelClick,
    handlePreviousStepReturnClick,
    handleReturnModalReturnClick,
  };
}

function App() {
  const bsaRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const isSaveProgressEnabled = useFlag(UNLEASH_TOGGLES.SaveProgress);
  const location = useLocation();
  const saveProgressLoadingStatus = useAppSelector(
    (state) => state.savedProgress.loading,
  );
  const [showMainPage, setShowMainPage] = useState(true);
  const {
    checkData,
    showNoActionsPage,
    handleClickOutside,
    handleExitModalSaveClick,
    handleExitModalDeleteClick,
    handleExitModalDeleteOkClick,
    handleExitModalDeleteCancelClick,
    handleSessionModalContinueClick,
    handleSessionModalDeleteClick,
    handleSessionModalDeleteOkClick,
    handleSessionModalDeleteCancelClick,
    handleReturnModalReturnClick,
  } = useApp(
    bsaRef,
    isSaveProgressEnabled,
    SAVE_SESSION_MODAL_ID,
    EXIT_DELETE_CONFIRM_MODAL_ID,
    CONTINUE_SESSION_MODAL_ID,
    ENTRY_DELETE_CONFIRM_MODAL_ID,
    RETURN_MODAL_ID,
    showModal,
    setShowMainPage,
  );

  useEffect(() => {
    if (isSaveProgressEnabled) checkData();
    else if (window.PORTFOLIO_BULK_SECURITY_ACTIONS_DATA?.data)
      startNewSession(
        window.PORTFOLIO_BULK_SECURITY_ACTIONS_DATA?.data,
        dispatch,
      );
  }, [checkData, dispatch, isSaveProgressEnabled]);

  useEffect(() => {
    if (isSaveProgressEnabled) void dispatch(fetchSavedProgress());
  }, [dispatch, isSaveProgressEnabled]);

  useEffect(() => {
    void dispatch(fetchFormattingSettings());
  }, [dispatch]);
  useEffect(() => {
    if (isSaveProgressEnabled)
      document.addEventListener('mousedown', handleClickOutside);

    return () => {
      if (isSaveProgressEnabled)
        document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, isSaveProgressEnabled]);

  useEffect(() => {
    dispatch(setCurrentPage(location.pathname));
  }, [dispatch, location]);

  useEffect(() => {
    return () => {
      resetState(dispatch);
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      deleteGroupsItemsFromWindow(process.env.NODE_ENV);
    };
  }, []);

  useEffect(() => {
    if (isSaveProgressEnabled) void dispatch(fetchTableConfigsThunk());
  }, [dispatch, isSaveProgressEnabled]);

  const exitModalButtons = [
    <IressButton
      key="Cancel"
      data-testid="modal-btn-delete-exit"
      onClick={handleExitModalDeleteClick}
      mode={IressButton.Mode.Secondary}
      type={IressButton.Type.Button}
    >
      Delete & exit
    </IressButton>,
    <IressButton
      key="save"
      data-testid="modal-btn-save-exit"
      onClick={handleExitModalSaveClick}
      mode={IressButton.Mode.Primary}
      type={IressButton.Type.Button}
    >
      Save & exit
    </IressButton>,
  ];

  const exitDeleteConfirmModalButtons = [
    <IressButton
      key="confirm"
      data-testid="modal-btn-delete-exit-confirm"
      onClick={handleExitModalDeleteOkClick}
      mode={IressButton.Mode.Secondary}
      type={IressButton.Type.Button}
    >
      Ok
    </IressButton>,
    <IressButton
      key="cancel"
      data-testid="modal-btn-delete-exit-cancel"
      onClick={handleExitModalDeleteCancelClick}
      mode={IressButton.Mode.Primary}
      type={IressButton.Type.Button}
    >
      Cancel
    </IressButton>,
  ];

  const continueSessionModalButtons = [
    <IressButton
      key="Delete"
      data-testid="modal-btn-delete"
      onClick={handleSessionModalDeleteClick}
      mode={IressButton.Mode.Secondary}
      type={IressButton.Type.Button}
    >
      Delete
    </IressButton>,
    <IressButton
      key="Continue"
      data-testid="modal-btn-continue"
      onClick={handleSessionModalContinueClick}
      mode={IressButton.Mode.Primary}
      type={IressButton.Type.Button}
    >
      Continue
    </IressButton>,
  ];

  const entryDeleteConfirmModalButtons = [
    <IressButton
      key="confirm"
      data-testid="modal-btn-delete-entry-confirm"
      onClick={handleSessionModalDeleteOkClick}
      mode={IressButton.Mode.Secondary}
      type={IressButton.Type.Button}
    >
      Ok
    </IressButton>,
    <IressButton
      key="cancel"
      data-testid="modal-btn-delete-entry-cancel"
      onClick={handleSessionModalDeleteCancelClick}
      mode={IressButton.Mode.Primary}
      type={IressButton.Type.Button}
    >
      Cancel
    </IressButton>,
  ];

  const returnModalButtons = [
    <ButtonSecondaryWithOnClick
      key="Cancel"
      buttonText="Cancel"
      dataTestId="modal-btn-cancel"
      onClick={() => showModal(RETURN_MODAL_ID, false)}
    />,
    <ButtonPrimary
      key="Return"
      buttonText="Return"
      dataTestid="btn-return"
      link="/"
      onClick={handleReturnModalReturnClick}
    />,
  ];

  return (
    <IressPanel
      padding={IressPanel.Padding.Md}
      background={IressPanel.Background.Page}
      data-testid="app"
    >
      {(() => {
        if (isSaveProgressEnabled && saveProgressLoadingStatus === 'pending') {
          return (
            <IressPanel
              className="bsa-container"
              style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner style={{ height: '64px', width: '64px' }} />
            </IressPanel>
          );
        } else if (showNoActionsPage()) {
          return <NoActionsPage />;
        } else {
          return (
            <div ref={bsaRef} className="bsa-container">
              {isSaveProgressEnabled && (
                <>
                  <Modal
                    id={SAVE_SESSION_MODAL_ID}
                    dataTestId={SAVE_SESSION_MODAL_ID}
                    title="Save this Session?"
                    message="If you save, this session will be available the next time you launch Bulk Security Actions (BSA)."
                    buttons={exitModalButtons}
                  />
                  <Modal
                    id={EXIT_DELETE_CONFIRM_MODAL_ID}
                    dataTestId={EXIT_DELETE_CONFIRM_MODAL_ID}
                    title="Delete saved session?"
                    message="All saved progress will be lost."
                    buttons={exitDeleteConfirmModalButtons}
                  />
                  <Modal
                    id={CONTINUE_SESSION_MODAL_ID}
                    dataTestId={CONTINUE_SESSION_MODAL_ID}
                    title="Continue with saved session?"
                    message="You can continue or delete it to start a new session. Deleted session cannot be restored."
                    disableBackdropClick={true}
                    noCloseButton={true}
                    buttons={continueSessionModalButtons}
                  />
                  <Modal
                    id={ENTRY_DELETE_CONFIRM_MODAL_ID}
                    dataTestId={ENTRY_DELETE_CONFIRM_MODAL_ID}
                    title="Delete saved session?"
                    message="All saved progress will be lost."
                    buttons={entryDeleteConfirmModalButtons}
                  />
                  <Modal
                    id={RETURN_MODAL_ID}
                    dataTestId={RETURN_MODAL_ID}
                    title="Return to previous step?"
                    message="If you leave now, your current adjustments will not be saved."
                    buttons={returnModalButtons}
                  />
                </>
              )}

              {showMainPage && <Outlet />}
            </div>
          );
        }
      })()}
    </IressPanel>
  );
}

export default App;
