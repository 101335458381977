import {
  SharedSecurityDetailsResponse,
  SharedSwitchAdjustment,
} from '@bsa/shared-types';
import { initialSwitchToSecurityState, SwitchState } from './switchSlice';
import { assignZeroIfNullOrUndefined } from '@app/utils';
import { PayloadAction } from '@reduxjs/toolkit';

export function setSwitchToSecurityHelper(
  index: number,
  currentSwitchToSecurities: SwitchState['switchToSecurities'],
  newSwitchToSecurity: SharedSecurityDetailsResponse,
): SwitchState['switchToSecurities'] {
  const newSwitchSecurities = [...currentSwitchToSecurities];
  newSwitchSecurities[index] = {
    securityDetails: newSwitchToSecurity,
    status: 'succeeded',
    message: 'Fetching market price & user currency for security',
    percentage: assignZeroIfNullOrUndefined(
      newSwitchSecurities[index]?.percentage,
    ),
    id: newSwitchToSecurity.securityId.toString(),
  };

  return newSwitchSecurities;
}

export function initialiseSwitchToSecurities(
  currentSwitchToSecurities: SwitchState['switchToSecurities'],
  index: number,
): void {
  while (currentSwitchToSecurities.length <= index) {
    currentSwitchToSecurities.push(initialSwitchToSecurityState);
  }
}

export function updateProposalHelper(
  state: SwitchState,
  action: PayloadAction<SharedSwitchAdjustment>,
): SwitchState['switchProposals'] {
  return state.switchProposals.map((proposal) => {
    if (
      proposal.accountId === action.payload.accountId &&
      proposal.securityId === action.payload.securityId
    ) {
      return action.payload;
    }
    return proposal;
  });
}
