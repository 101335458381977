import { RefObject, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useSwitchState } from './useSwitchState';
import { SharedSwitchPayload, SharedSwitchSecurity } from '@bsa/shared-types';
import { applySwitchAllocationModalId } from '../ApplySwitchAllocationModal';
import { showModal } from '@iress/components-react';
import { useAppDispatch } from '@app/app/hooks';
import { createSwitchInstructionThunk } from '@app/features/Switch';

interface UseSwitchSubmissionParams {
  gridRef: RefObject<AgGridReact>;
  switchState: ReturnType<typeof useSwitchState>;
}

export const useSwitchSubmission = ({
  switchState,
}: UseSwitchSubmissionParams) => {
  const {
    states: {
      originalProposals,
      selectedSecurity,
      inputtedCurrentHolding,
      switchToSecurities,
    },
  } = switchState;

  const dispatch = useAppDispatch();

  const getBuySecurities = useCallback(() => {
    return switchToSecurities.map((security) => ({
      securityDetails: security.securityDetails,
      percentage: security.percentage,
    })) as SharedSwitchSecurity[];
  }, [switchToSecurities]);

  const getSellSecurity = useCallback(() => {
    const securityDetails = selectedSecurity;
    const percentage = inputtedCurrentHolding;
    return {
      securityDetails,
      percentage,
    } as SharedSwitchSecurity;
  }, [selectedSecurity, inputtedCurrentHolding]);

  const getProposals = useCallback(() => {
    const adjustments = originalProposals;
    const buySecurities = getBuySecurities();
    const sellSecurity = getSellSecurity();

    return {
      sellSecurity,
      buySecurities,
      adjustments,
    } as SharedSwitchPayload;
  }, [originalProposals, getBuySecurities, getSellSecurity]);

  const handleSubmitAsync = useCallback(() => {
    try {
      showModal(applySwitchAllocationModalId, false);
      const proposals = getProposals();
      void dispatch(createSwitchInstructionThunk(proposals));
    } catch (error) {
      console.error('handleSwitchInstruction error', error);
    }
  }, [getProposals, dispatch]);

  const handleFormOnSubmitEvent = useCallback(() => {
    try {
      handleSubmitAsync();
    } catch (error) {
      console.error('Switch error:', error);
    }
  }, [handleSubmitAsync]);

  return {
    handleFormOnSubmitEvent,
  };
};
