import { IressButton, IressStack } from '@iress/components-react';

function ButtonExit(
  props: Readonly<{
    onClick: () => void;
  }>,
) {
  return (
    <IressStack gutter={IressStack.Gutter.Md}>
      <IressButton
        mode={IressButton.Mode.Secondary}
        type={IressButton.Type.Button}
        key="previous_step"
        data-testid="btn-previous-step"
        onClick={props.onClick}
      >
        Back
      </IressButton>
    </IressStack>
  );
}

export default ButtonExit;
