import { createAccountSwitchAdjustmentOnProposal } from '@app/services/proposals';
import { getSelectedSecurityMarketPriceService } from '@app/services/securities';
import { createSwitchInstruction } from '@app/services/switch';
import {
  SharedProposalRequestPayload,
  SharedSecurityDetailsResponse,
  SharedSwitchManualAdjustment,
  SharedSwitchPayload,
} from '@bsa/shared-types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateSwitchToSecurityPayload } from './switchSlice';
import { assignZeroIfNullOrUndefined } from '@app/utils';
import { switchManualAdjustments } from '@app/services/manualAdjustments';

export const createSwitchInstructionThunk = createAsyncThunk(
  'Switch/createSwitchInstruction',
  async ({ sellSecurity, buySecurities, adjustments }: SharedSwitchPayload) => {
    return createSwitchInstruction(sellSecurity, buySecurities, adjustments);
  },
);

export const createSwitchProposalThunk = createAsyncThunk(
  'Switch/createSwitchProposal',
  async (payload: SharedProposalRequestPayload[]) => {
    return createAccountSwitchAdjustmentOnProposal(payload);
  },
);

export const switchManualAdjustmentThunk = createAsyncThunk(
  'Switch/switchManualAdjustment',
  async ({ type, value, proposal, security }: SharedSwitchManualAdjustment) => {
    return switchManualAdjustments(type, value, proposal, security);
  },
);

export const fetchSwitchToSecurityMarketPrice = createAsyncThunk(
  'Switch/fetchSwitchToSecurityMarketPrice',
  async ({
    index,
    updatedSwitchToSecurity,
  }: UpdateSwitchToSecurityPayload): Promise<UpdateSwitchToSecurityPayload> => {
    const result = await getSelectedSecurityMarketPriceService(
      updatedSwitchToSecurity.securityId,
    );
    const updateSecurityDetails: SharedSecurityDetailsResponse = {
      ...updatedSwitchToSecurity,
      marketPrice: assignZeroIfNullOrUndefined(result.marketPrice),
      userCurrency: result.userCurrency as string,
    };
    return {
      index,
      updatedSwitchToSecurity: updateSecurityDetails,
    };
  },
);
