export enum AdjustmentType {
    Volume = 0,
    Value = 1,
    Percent = 2,
    TargetVolume = 3,
    TargetValue = 4,
    SwitchPercent = 5,
  }
  
  export enum InstructionAdjustmentType {
    Volume = 0,
    Value = 1,
    Percent = 2,
    IncreasePercentTo = 3,
    DecreasePercentTo = 4,
    DecreaseVolumeTo = 5,
    IncreaseVolumeTo = 6,
    FullShare = 7
  }
  
  export enum AllocationType {
    ProRataDecreaseTo = 0,
    EquallyIncreaseTo = 1,
    ProRataIncreaseTo = 2,
    ProRataFullShare = 3,
    EquallyFullShare = 4
  }
  
  export enum SharedManualAdjustmentType {
    ProposedPercent = 'ProposedPercent',
    ProposedUnits = 'ProposedUnits',
    ProposedValue = 'ProposedValue',
    TargetPercent = 'TargetPercent',
    TargetUnits = 'TargetUnits',
    TargetValue = 'TargetValue',
  }

  export enum SharedSwitchManualAdjustmentType {
    ProposedUnits = 'ProposedUnits',
    ProposedValue = 'ProposedValue',
    TargetValue = 'TargetValue',
  }

  export enum SharedSwitchType {
    BUY = 'BUY',
    SELL = 'SELL',
    UNKNOWN = 'UNKNOWN',
  }
  
  export type Adjustment = {
    portfolio: string;
    accountGroup: string;
    accountGroupCode: string;
    account: string;
    externalAccountId: string;
    instrument: string;
    SEDOL: string;
    ISIN: string;
    security: string;
    'marketPrice (CCY)': string;
    nativePrice: string;
    nativeCurrency: string;
    assetClassShortName: string;
    'currentValue (CCY)': string;
    'targetValue (CCY)': string;
    'proposedValue (CCY)': string;
    targetNativeValue: string;
    proposedNativeValue: string;
    currentUnits: string;
    targetUnits: string;
    proposedUnits: string;
    currentWeight: string;
    targetWeight: string;
    proposedWeight: string;
    'currentCashAvailable (CCY)': string;
    'proposedCashAvailable (CCY)': string;
    'currentCash %': string;
    'assetClass %': string;
    taxType: string;
    'taxableGain/Loss (CCY)': string;
    'gain/Loss (CCY)': string;
    status: string;
    accountId: string;
    portfolioValue: string;
    serviceType: string;
    hierarchy: string[];
    allowPartialVolume: boolean;
  };
  
  export type AccountGroupAdjustment = {
    accountGroup: Adjustment;
    accounts: Adjustment[];
  };
  
  export type AccountLevelResponse = {
    portfolio: string;
    accountGroupCode: string;
    accountGroupName: string;
    externalAccountId: string;
    account: string;
    serviceType: string;
    targetSet: string;
    value: string; // account total value
    cashAmount: string; // cash available
    cashPercentage: string;
    taxType: string;
    units: string;
    costBase: string;
    holdingValue: string;
    gainLoss: string;
    taxableGainLoss: string;
    weight: string;
    selected: boolean;
    accountId: number;
    exclude_from_modelling: boolean;
    status: string;
    assetClassPercentage: string;
  };
  
  export interface AccountGroupLevelResponse {
    portfolio: string;
    accountGroupCode: string;
    accountGroupName: string;
    allocationTarget: string;
    serviceType: string;
    planType: string;
    investmentAdvisor: string;
    riskProfile: string;
    targetSet: string;
    cashPercentage: string;
    cashAmount: string;
    value: string;
    units: string;
    costBase: string;
    holdingValue: string;
    weight: string;
    selected: boolean;
    accounts: AccountLevelResponse[];
    status: string;
    assetClassPercentage: string;
  }
  
  export interface SharedAccountsResponse {
    withoutZeroHoldings: Array<AccountLevelResponse | AccountGroupLevelResponse>;
    withZeroHoldings: Array<AccountLevelResponse | AccountGroupLevelResponse>;
  }
  
  export type SharedViewLevelParam = 'accounts' | 'accountGroups';
  
  export interface SharedSecurityDetailsResponse {
    securityId: number;
    code: string;
    exchange: string;
    description: string;
    sedol: string;
    isin: string;
    nativeCurrency: string;
    nativePrice: string;
    userCurrency: string;
    marketPrice: string;
    assetClass: string;
    searchType: 'ISIN' | 'SEDOL' | 'CODE/DESCRIPTION';
    allowPartialVolume: boolean;
  }
  export interface SharedSetting {
    dp: number;
    maxdp: number;
    pospre: string;
    negpre: string;
    pospost: string;
    negpost: string;
    comma: boolean;
  }
  
  export interface SharedSysadminFormattingResponse {
    [element: string]: {
      [display: string]: SharedSetting;
    };
  }
  
  export interface SharedProposalResponse {
    proposals: ProposalResponse[];
    failedProposals: ProposalResponse[];
  }
  export type ProposalResponse = {
    accountid: number;
  };
  
  export type SharedAccountAdjustmentDetails = {
    accountId: number;
    adjustmentType: AdjustmentType;
    adjustmentValue: number;
  };

  export interface SharedProposalRequestPayload {
    security_id: number;
    adjustments: SharedAccountAdjustmentDetails[];
  }

  export interface SharedAccountManualAdjustment {
    type: SharedManualAdjustmentType;
    value: string;
    proposal: Adjustment;
  }
  
  export interface SharedAccountGroupManualAdjustment {
    type: SharedManualAdjustmentType;
    value: string;
    proposal: AccountGroupAdjustment;
  }

  export interface SharedSwitchManualAdjustment {
    type: SharedSwitchManualAdjustmentType;
    value: string;
    proposal: SharedSwitchAdjustment;
    security: SharedSecurityDetailsResponse
  }
  
  export interface SharedInstructionAdjustment {
    type: InstructionAdjustmentType;
    value: string;
  }
  
  export interface SharedInstructionAccountGroupAdjustment
    extends SharedInstructionAdjustment {
    allocationType: AllocationType;
    excludeServiceTypes?: string[];
  }

  export interface SharedInstructionDecreaseToResponse {
    proposals: Adjustment[];
  }
  
  export interface SharedInstructionIncreaseToResponse {
    proposals: Adjustment[];
  }
  
  export interface SharedInstructionAccountGroupIncreaseToResponse {
    proposals: AccountGroupAdjustment[];
  }
  
  export interface SharedInstructionAccountGroupDecreaseToResponse {
    proposals: AccountGroupAdjustment[];
  }
  
  export interface SharedInstructionAccountGroupIncreaseToResponse {
    proposals: AccountGroupAdjustment[];
  }
  
  export interface SharedColumnData {
    xplanColumnId?: number;
    colId: string;
    width: number;
    hide: boolean;
    pinned: null | string;
    sort: null | string;
    sortIndex: null | number;
    aggFunc: null | string;
    rowGroup: boolean;
    rowGroupIndex: null | number;
    pivot: boolean;
    pivotIndex: null | number;
    flex: null | number;
  }
  
  export interface SharedTableConfig {
    config_id?: number;
    code: string;
    data: SharedColumnData[];
  }
  
  export interface SharedSwitchSecurity {
    securityDetails: SharedSecurityDetailsResponse
    /** The percentage when selling is the current holding percentage we want to sell
     * The percetnage when buying is the percentage of the security we want to buy
    */
    percentage: string;
  }

  export interface SharedSwitchPayload {
    sellSecurity: SharedSwitchSecurity;
    buySecurities: SharedSwitchSecurity[];
    adjustments: SharedSwitchAdjustment[]; 
  }
  
  export interface SharedSwitchAdjustment {
    account: string;
    accountId: string;
    securityId: string;
    security: string;
    portfolio: string;
    switchType: SharedSwitchType;
    'currentValue (CCY)': string;
    'targetValue (CCY)': string;
    'proposedValue (CCY)': string;
    proposedUnits: string;
    currentUnits: string;
    hierarchy: string[];
  }
  
  export interface SharedSwitchResponse {
    proposals: SharedSwitchAdjustment[];
  }

  export enum SwitchAdjustmentType {
    DecreaseVolumeTo = 0,
    IncreaseVolumeTo = 1,
  }

  export interface SharedSwitchAdjustmentDetails {
    type: SwitchAdjustmentType;
    value: string;
  }
