import { UNLEASH_TOGGLES } from '@app/app/unleash';
import { useFlag } from '@unleash/proxy-client-react';
import { PositionsGrid } from '@app/components/PositionsGrid';
import { ZeroHoldingsPanel } from '@app/components/ZeroHoldingsPanel';
import { SearchForm } from '@app/components/SearchForm';
import { SecurityDetails } from '@app/components/SecurityDetails';
import {
  IressCol,
  IressPanel,
  IressRow,
  IressStack,
  showModal,
} from '@iress/components-react';
import { MainPageTitle } from '@app/components/Titles';
import { AgGridReact } from 'ag-grid-react';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/app/hooks';
import { AppDispatch, RootState } from '@app/app/store';
import {
  AccountGroupLevelResponse,
  AccountLevelResponse,
  Adjustment,
  SharedSecurityDetailsResponse,
} from '@bsa/shared-types';
import {
  setOriginalProposals,
  setProposals,
  setSelectedProposals,
} from '@app/features/AccountGroups';

import {
  setSelectedSwitches,
  setOriginalProposals as setSwitchOriginalProposals,
  setSwitchProposals,
} from '@app/features/Switch';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { CtaPanel } from '@app/components/CtaPanel';
import ButtonContinueSwitch from '@app/components/Button/ButtonContinueSwitch';
import ButtonExit from '@app/components/Button/ButtonExit';
import { SAVE_SESSION_MODAL_ID } from '@app/app/App';
import {
  checkRowsForSwitch,
  createAccountsInstructionsRowData,
  createAccountSwitchesRowData,
  createGroupAdjustments,
  createGroupsInstructionsRowData,
} from './MainPage.utils';
import { SharedViewLevelParamExtended } from '@app/types/sharedViewLevelParamExtended';
import { SelectedLevels, ViewLevels } from '@app/utils/constants';

export type Positions = AccountGroupLevelResponse | AccountLevelResponse;

export const useMainPage = (
  dispatch: AppDispatch,
  gridRef: RefObject<AgGridReact>,
  navigate: NavigateFunction,
  setShowValidationErrorMessage: (value: boolean) => void,
  security: SharedSecurityDetailsResponse,
  viewLevel: SharedViewLevelParamExtended,
) => {
  const handleOnClick = useCallback(
    (navigation: string) => {
      let isFilteredNodeSelected = false;
      const selectedPositions: Positions[] = [];
      gridRef.current?.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          isFilteredNodeSelected = true;
          selectedPositions.push({ ...node.data });
        }
      });
      if (isFilteredNodeSelected) {
        if (gridRef.current) {
          let instructionData: Adjustment[] = [];
          if (viewLevel === ViewLevels.accountGroups) {
            const originalGroupProposalData = createGroupAdjustments(
              selectedPositions as AccountGroupLevelResponse[],
              security,
            );
            dispatch(setOriginalProposals(originalGroupProposalData));
            instructionData = createGroupsInstructionsRowData(
              originalGroupProposalData,
            );
          } else {
            instructionData = createAccountsInstructionsRowData(
              selectedPositions,
              security,
            );
            dispatch(setOriginalProposals(instructionData));
          }
          dispatch(setProposals(instructionData));
          dispatch(setSelectedProposals(instructionData));

          navigate(navigation);
        }
      } else {
        setShowValidationErrorMessage(true);
      }
    },
    [
      gridRef,
      dispatch,
      setShowValidationErrorMessage,
      navigate,
      security,
      viewLevel,
    ],
  );

  const handleOnSwitchClick = useCallback(
    (navigation: string) => {
      const selectedPositions: AccountLevelResponse[] = [];
      gridRef.current?.api.forEachNodeAfterFilter((node) => {
        if (checkRowsForSwitch(node)) {
          selectedPositions.push({ ...node.data });
        }
      });
      if (selectedPositions.length > 0) {
        if (gridRef.current) {
          const instructionData = createAccountSwitchesRowData(
            selectedPositions,
            security,
          );
          dispatch(setSwitchOriginalProposals(instructionData));
          dispatch(setSwitchProposals(instructionData));
          dispatch(setSelectedSwitches(instructionData));
          navigate(navigation);
        }
      } else {
        setShowValidationErrorMessage(true);
      }
    },
    [gridRef, dispatch, setShowValidationErrorMessage, navigate, security],
  );

  return {
    handleOnClick,
    handleOnSwitchClick,
  };
};

function MainPage() {
  const isSaveProgressEnabled = useFlag(UNLEASH_TOGGLES.SaveProgress);
  const isSwitchEnabled = useFlag(UNLEASH_TOGGLES.Switch);
  const gridRef = useRef<AgGridReact>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accountGroupItems = useAppSelector(
    (state: RootState) => state.accountGroups.accountGroupItems,
  );
  const selectedPositions = useAppSelector(
    (state: RootState) => state.accountGroups.selectedPositions,
  );
  const viewLevel = useAppSelector(
    (state: RootState) => state.accountGroups.viewLevel,
  );
  const { loading } = accountGroupItems;

  // selectedLevel should be singular not plural
  const selectedLevel =
    viewLevel === ViewLevels.accountGroups
      ? SelectedLevels.accountGroup
      : SelectedLevels.account;

  const [showValidationErrorMessage, setShowValidationErrorMessage] =
    useState<boolean>(false);

  const resetErrorMessage = useCallback(() => {
    setShowValidationErrorMessage(false);
  }, []);
  const selectedSecurity = useAppSelector(
    (state: RootState) => state.securities.selectedSecurity,
  );
  const errorMessage = `Please select at least one ${selectedLevel} with holdings before proceeding`;

  const { handleOnClick, handleOnSwitchClick } = useMainPage(
    dispatch,
    gridRef,
    navigate,
    setShowValidationErrorMessage,
    selectedSecurity,
    viewLevel,
  );

  useEffect(() => {
    if (
      gridRef.current &&
      gridRef.current.api?.getSelectedRows().length !== 0
    ) {
      setShowValidationErrorMessage(false);
    }
  }, [selectedPositions]);

  return (
    <div
      style={{
        height: '100%',
        flexDirection: 'column',
        display: 'flex',
        gap: '8px',
      }}
    >
      <IressRow>
        <IressCol>
          <MainPageTitle />
        </IressCol>
        {loading === 'succeeded' ? (
          <IressCol>
            <CtaPanel
              selectedRowCount={selectedPositions.length.toString()}
              totalRowCount={(
                gridRef.current?.api?.getDisplayedRowCount() ?? 0
              ).toString()}
              selectedLevel={selectedLevel}
              showErrorMessage={showValidationErrorMessage}
              errorMessage={errorMessage}
            >
              {isSaveProgressEnabled && (
                <ButtonExit
                  onClick={() => showModal(SAVE_SESSION_MODAL_ID, true)}
                />
              )}
              <ButtonContinueSwitch
                onClick={() => handleOnClick('/instructions')}
                buttonText="Continue"
              />
              {isSwitchEnabled && viewLevel === 'accounts' && (
                <ButtonContinueSwitch
                  onClick={() => handleOnSwitchClick('/switch')}
                  buttonText="Switch"
                />
              )}
            </CtaPanel>
          </IressCol>
        ) : (
          <></>
        )}
      </IressRow>
      <IressPanel
        padding={IressPanel.Padding.Sm}
        background={IressPanel.Background.Default}
      >
        <IressStack gutter={IressStack.Gutter.Sm}>
          <SearchForm resetErrorMessage={resetErrorMessage} />
          <SecurityDetails />
        </IressStack>
      </IressPanel>
      {loading === 'succeeded' && <ZeroHoldingsPanel />}
      <PositionsGrid gridRef={gridRef} />
    </div>
  );
}
export default MainPage;
