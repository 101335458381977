import {
  SharedSecurityDetailsResponse,
  SharedSwitchAdjustment,
  SharedSwitchManualAdjustmentType,
  SharedSwitchType,
} from '@bsa/shared-types';
import {
  CellClassParams,
  Column,
  EditableCallbackParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { camelCaseToTitle } from '../AgGrid/getters';
import { SwitchSecurityState } from '@app/features/Switch';

export function getSwitchTypeValue(
  params: ValueGetterParams<SharedSwitchAdjustment>,
) {
  return params.data?.switchType === SharedSwitchType.UNKNOWN
    ? ''
    : params.data?.switchType;
}

export function getManualAdjustmentTypeFromColumn(column: Column) {
  /**
   * modify the column field to match the SharedManualAdjustmentType Enum
   */
  const columnId = column
    .getColId()
    .replace(' (CCY)', '') // Remove currency notation
    .replace('Weight', 'Percent'); // Replace 'Weight' with 'Percent'
  const fieldName = camelCaseToTitle(columnId).replaceAll(/\s/g, '');

  /**
   * convert SharedManualAdjustmentType Enum to array to check column field against its values
   */
  const manualAdjustmentTypes = new Object(SharedSwitchManualAdjustmentType);
  /**
   * check if the column field is a valid SharedManualAdjustmentType Enum
   */
  return fieldName in manualAdjustmentTypes ? fieldName : null;
}

export const isEditableCell = (
  params:
    | CellClassParams<SharedSwitchAdjustment>
    | EditableCallbackParams<SharedSwitchAdjustment>,
) => {
  return params?.data?.switchType === SharedSwitchType.BUY;
};

export function getSecurityForManualAdjustment(
  switchToSecurities: SwitchSecurityState[],
  proposal: SharedSwitchAdjustment,
): SharedSecurityDetailsResponse | undefined {
  return switchToSecurities.find(
    (security) =>
      security.securityDetails.securityId.toString() ===
      proposal.securityId.toString(),
  )?.securityDetails;
}
