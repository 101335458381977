import { useAppDispatch, useAppSelector } from '@app/app/hooks';
import { BSAColumnState, updateColumnsState } from '@app/components/AgGrid';
import {
  getManualAdjustmentTypeFromColumn,
  getSecurityForManualAdjustment,
} from '@app/components/SwitchGrid/SwitchGrid.utils';

import {
  setSelectedSwitches,
  switchManualAdjustmentThunk,
} from '@app/features/Switch';
import {
  SharedSwitchManualAdjustmentType,
  SharedSwitchAdjustment,
  SharedSwitchType,
} from '@bsa/shared-types';
import {
  CellEditRequestEvent,
  CellValueChangedEvent,
  FilterChangedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { Dispatch, SetStateAction, useCallback } from 'react';

export function useSwitchGridEventHandlers(
  setProposalsCount: Dispatch<SetStateAction<number>>,
  setTotalRowsCount: Dispatch<SetStateAction<number>>,
  setShowValidationErrorMessage: (value: boolean) => void,
) {
  const switchTableConfig = useAppSelector(
    (state) => state.tableConfig.data.switch_accounts,
  );

  const switchToSecurities = useAppSelector(
    (state) => state.switch.switchToSecurities,
  );

  const dispatch = useAppDispatch();

  const onSelectionOrFilterChanged = useCallback(
    (
      event:
        | SelectionChangedEvent<SharedSwitchAdjustment>
        | FilterChangedEvent<SharedSwitchAdjustment>,
    ) => {
      const filterAndSelectedTransactions: SharedSwitchAdjustment[] = [];
      let filteredTotalAccounts = 0;
      let filterAndSelectedAccounts = 0;
      event.api.forEachNodeAfterFilter((node) => {
        if (node.data?.switchType === SharedSwitchType.UNKNOWN) {
          filteredTotalAccounts++;
          if (node.isSelected()) {
            filterAndSelectedAccounts++;
          }
        }
        if (node.data?.securityId && node.isSelected()) {
          filterAndSelectedTransactions.push(node.data);
        }
      });
      dispatch(setSelectedSwitches(filterAndSelectedTransactions));
      setProposalsCount(filterAndSelectedAccounts);
      setTotalRowsCount(filteredTotalAccounts);
      setShowValidationErrorMessage(false);
    },
    [
      dispatch,
      setProposalsCount,
      setShowValidationErrorMessage,
      setTotalRowsCount,
    ],
  );
  const onColumnsStateUpdate = useCallback(
    (event: BSAColumnState) =>
      updateColumnsState(event, switchTableConfig, dispatch),
    [dispatch, switchTableConfig],
  );

  const onCellEditing = useCallback(
    (
      event:
        | CellValueChangedEvent<SharedSwitchAdjustment>
        | CellEditRequestEvent<SharedSwitchAdjustment>,
    ) => {
      const { column, newValue, data, oldValue } = event;

      const originalData = { ...data, [column.getColId()]: oldValue };
      const fieldName = getManualAdjustmentTypeFromColumn(column);
      const security = getSecurityForManualAdjustment(
        switchToSecurities,
        originalData,
      );
      if (fieldName === null || security === undefined) return;

      void dispatch(
        switchManualAdjustmentThunk({
          value: newValue,
          proposal: originalData,
          type: fieldName as SharedSwitchManualAdjustmentType,
          security,
        }),
      );
    },
    [dispatch, switchToSecurities],
  );
  return {
    onSelectionOrFilterChanged,
    onColumnsStateUpdate,
    onCellEditing,
  };
}
