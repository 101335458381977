import {
  IressCol,
  IressForm,
  IressRow,
  ButtonMode,
  IressField,
  IressSelect,
  IressStack,
  showModal,
} from '@iress/components-react';
import { InputUnitPrice, InputPercentage } from '../Input';
import { ButtonSubmit, ButtonLinkWithIcon, ButtonDeleteIcon } from '../Button';
import ApplyInstructionErrorModal from '../InstructionsForm/ApplyInstructionErrorModal';
import { SwitchingToSecurity } from '../SwitchingToSecurity';
import {
  assignZeroIfNullOrUndefined,
  modifySecuritiesToMenuItems,
} from '@app/utils';
import { useSwitchState } from './hooks/useSwitchState';
import { RefObject, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useSwitchSubmission } from './hooks/useSwitchSubmission';
import ApplySwitchAllocationModal, {
  applySwitchAllocationModalId,
} from './ApplySwitchAllocationModal';
import Decimal from 'decimal.js';

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const SwitchForm = ({ gridRef }: Readonly<Props>) => {
  const percentageErrorText = 'Please enter a value between 0.01 and 100.00';

  const switchState = useSwitchState();
  const {
    states: {
      selectedLevel,
      inputtedCurrentHolding,
      switchToSecurities,
      noResultText,
      isAdditionalSwitchVisible,
    },
    handlers: {
      handleAddAnotherClick,
      handleDeleteSecurityClick,
      handleCurrentHoldingChange,
      handleFirstSwitchSecurityChange,
      handleSecondSwitchSecurityChange,
      handleFirstSwitchPercentageChange,
      handleSecondSwitchPercentageChange,
      handleComboBoxInputChange,
    },
  } = switchState;

  const { handleFormOnSubmitEvent } = useSwitchSubmission({
    gridRef,
    switchState,
  });

  const checkBuySecuritiesAreSumedTo100 = useCallback(() => {
    const security1Percentage =
      Number.parseFloat(switchToSecurities[0]?.percentage) || 0;
    const security2Percentage =
      Number.parseFloat(switchToSecurities[1]?.percentage) || 0;
    if (security1Percentage + security2Percentage < 100) {
      showModal(applySwitchAllocationModalId, true);
    } else {
      handleFormOnSubmitEvent();
    }
  }, [switchToSecurities, handleFormOnSubmitEvent]);

  const calculateMaxValue = (index: number) => {
    return new Decimal(100)
      .minus(
        new Decimal(
          assignZeroIfNullOrUndefined(
            switchToSecurities[index === 0 ? 1 : 0]?.percentage,
          ) ?? 0,
        ),
      )
      .toString();
  };

  return (
    <IressForm
      data-testid="switch-form"
      disabledClearOnSubmit
      hiddenErrorSummary
      onSubmit={checkBuySecuritiesAreSumedTo100}
    >
      <IressStack gutter={IressStack.Gutter.Md}>
        <IressRow
          gutter={IressRow.Gutter.Md}
          verticalAlign={IressRow.VerticalAlign.Bottom}
          horizontalAlign={IressRow.HorizontalAlign.Left}
        >
          <IressCol span={IressCol.Span.Three}>
            <IressField
              label="Instruction"
              hint={`Adjustments will be applied at an ${selectedLevel} level`}
              data-testid="instruction-label"
              valueMissing="Please select an instruction"
            >
              {
                <IressSelect
                  required
                  placeholder="Select an instruction"
                  data-testid="instruction-select"
                  value="switch"
                >
                  <option key="switch" value="switch">
                    Switch from selected security
                  </option>
                </IressSelect>
              }
            </IressField>
          </IressCol>
          <IressCol span={IressCol.Span.One}>
            <InputUnitPrice />
          </IressCol>
          <IressCol span={IressCol.Span.One}>
            <InputPercentage
              label="Current Holding"
              key="CurrentHolding"
              dataTestId="current-holding"
              min="0.01"
              max="100.00"
              step="0.01"
              valueMissing="Please enter a current holding"
              rangeOverflow={percentageErrorText}
              rangeUnderflow={percentageErrorText}
              stepMismatch={percentageErrorText}
              handleChange={handleCurrentHoldingChange}
              inputValue={inputtedCurrentHolding}
            />
          </IressCol>
          <IressCol span={IressCol.Span.Four}>
            <SwitchingToSecurity
              id="switching-security-1"
              cbHandleInputChange={handleComboBoxInputChange}
              cbNoResultsText={noResultText}
              cbDataTestId="switching-security-combobox-1"
              cbOnchange={handleFirstSwitchSecurityChange}
              cbSelectedOption={
                modifySecuritiesToMenuItems([
                  switchToSecurities[0]?.securityDetails,
                ])?.[0]
              }
              inputLabel="Allocation"
              inputKey="Allocation-1"
              inputDataTestId="allocation-1"
              inputMin="0.01"
              inputMax={calculateMaxValue(0)}
              inputStep="0.01"
              inputValueMissing="Please enter an allocation"
              inputOnChange={handleFirstSwitchPercentageChange}
              inputValue={switchToSecurities[0]?.percentage}
              inputErrorText={
                'Please enter a value between 0.01 and ' + calculateMaxValue(0)
              }
              hiddenLabel={false}
            />
          </IressCol>
          <IressCol span={IressCol.Span.One}>
            <ButtonSubmit
              key="apply"
              buttonText="Apply"
              dataTestId="submit-btn"
              mode={ButtonMode.Primary}
              isLoaderVisible={false}
            />
          </IressCol>
        </IressRow>
        <IressRow
          gutter={IressRow.Gutter.Md}
          verticalAlign={IressRow.VerticalAlign.Bottom}
          horizontalAlign={IressRow.HorizontalAlign.Left}
        >
          <IressCol span={IressCol.Span.Four} offset={IressCol.Offset.Five}>
            {isAdditionalSwitchVisible && (
              // Visual fix for The button to be aligned with the combobox field above
              <div style={{ paddingLeft: '8px' }}>
                <ButtonLinkWithIcon
                  onClick={handleAddAnotherClick}
                  dataTestId="add-another-security"
                  iconName="plus-circle"
                  linkText="Add another security"
                />
              </div>
            )}
            {!isAdditionalSwitchVisible && (
              <SwitchingToSecurity
                id="switching-security-2"
                cbHandleInputChange={handleComboBoxInputChange}
                cbNoResultsText={noResultText}
                cbDataTestId="switching-security-combobox-2"
                cbOnchange={handleSecondSwitchSecurityChange}
                cbSelectedOption={
                  modifySecuritiesToMenuItems([
                    switchToSecurities[1]?.securityDetails,
                  ])?.[0]
                }
                inputLabel="Allocation"
                inputKey="Allocation-2"
                inputDataTestId="allocation-2"
                inputMin="0.01"
                inputMax={calculateMaxValue(1)}
                inputStep="0.01"
                inputValueMissing="Please enter an allocation"
                inputOnChange={handleSecondSwitchPercentageChange}
                inputValue={switchToSecurities[1]?.percentage}
                inputErrorText={
                  'Please enter a value between 0.01 and ' +
                  calculateMaxValue(1)
                }
                hiddenLabel={true}
              />
            )}
          </IressCol>
          <IressCol span={IressCol.Span.One}>
            {!isAdditionalSwitchVisible && (
              <ButtonDeleteIcon onClick={handleDeleteSecurityClick} />
            )}
          </IressCol>
        </IressRow>
      </IressStack>
      <ApplySwitchAllocationModal
        handleFormOnSubmitEvent={handleFormOnSubmitEvent}
      />
      <ApplyInstructionErrorModal />
    </IressForm>
  );
};

export default SwitchForm;
