import { UNLEASH_TOGGLES } from '@app/app/unleash';
import { useFlag } from '@unleash/proxy-client-react';
import { SwitchForm } from '@app/components/SwitchForm';
import {
  IressButton,
  IressCol,
  IressPanel,
  IressRow,
  IressStack,
  showModal,
} from '@iress/components-react';
import { SwitchPageTitle } from '@app/components/Titles';
import { ButtonPrimary } from '@app/components/Button';
import { Modal } from '@app/components/Modal';
import ButtonPreviousStep from '@app/components/Button/ButtonPreviousStep';
import { AgGridReact } from 'ag-grid-react';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { CtaPanel } from '@app/components/CtaPanel';
import { useAppDispatch, useAppSelector } from '@app/app/hooks';
import { AppDispatch, RootState } from '@app/app/store';
import { SharedSwitchAdjustment } from '@bsa/shared-types';
import ButtonExit from '@app/components/Button/ButtonExit';
import { SAVE_SESSION_MODAL_ID, RETURN_MODAL_ID } from '@app/app/App';
import { SwitchGrid } from '@app/components/SwitchGrid';
import { createSwitchProposalThunk } from '@app/features/Switch';
import { modifySwitchInstructionsToProposals } from '@app/utils/modifiers.utils';
import { handleGenerateProposalStatus } from './InstructionsPage.utils';
import { LoadingStatus } from '@app/types/loadingStatus';

const generateProposalErrorModalId = 'generate-proposal-error-modal-ok';

export const useSwitchPage = (
  dispatch: AppDispatch,
  gridRef: RefObject<AgGridReact>,
  setShowValidationErrorMessage: (value: boolean) => void,
  setValidationErrorMessage: (value: string) => void,
  setIsLoading: (value: boolean) => void,
) => {
  const handleOnClick = useCallback(() => {
    if (!gridRef.current) return;

    let isFilteredNodeSelected = false;
    const updatedProposals: SharedSwitchAdjustment[] = [];

    gridRef.current.api.forEachNodeAfterFilter((node) => {
      if (node.isSelected()) {
        isFilteredNodeSelected = true;
        const adjustmentData = node.data as SharedSwitchAdjustment;
        if (Number(adjustmentData.proposedUnits) !== 0) {
          updatedProposals.push(adjustmentData);
        }
      }
    });

    if (!isFilteredNodeSelected) {
      setValidationErrorMessage(
        `Please select at least one account before generating proposals`,
      );
      setShowValidationErrorMessage(true);
    } else if (updatedProposals.length === 0) {
      setValidationErrorMessage(
        `Please apply an instruction before generating proposals`,
      );
      setShowValidationErrorMessage(true);
    } else {
      setIsLoading(true);
      void dispatch(
        createSwitchProposalThunk(
          modifySwitchInstructionsToProposals(updatedProposals),
        ),
      );
    }
  }, [
    gridRef,
    setValidationErrorMessage,
    setShowValidationErrorMessage,
    dispatch,
    setIsLoading,
  ]);

  const handleGridRowsChange = useCallback(
    (loadingStatus: LoadingStatus) => {
      if (loadingStatus === 'succeeded') {
        gridRef.current?.api?.forEachNode((node) => {
          node.setSelected(true);
        });
      }
    },
    [gridRef],
  );

  return {
    handleOnClick,
    setShowValidationErrorMessage,
    setIsLoading,
    handleGridRowsChange,
  };
};

function SwitchPage() {
  const dispatch = useAppDispatch();
  const [validationErrorMessage, setValidationErrorMessage] =
    useState<string>('');
  const generateSwitchProposalLoadingStatus = useAppSelector(
    (state: RootState) => state.switch.generateSwitchProposalLoadingStatus,
  );
  const applySwitchLoadingStatus = useAppSelector(
    (state: RootState) => state.switch.applySwitchesLoadingStatus,
  );
  const gridRef = useRef<AgGridReact>(null);
  const [showValidationErrorMessage, setShowValidationErrorMessage] =
    useState<boolean>(false);
  const [proposalsCount, setProposalsCount] = useState(0);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isSaveProgressEnabled = useFlag(UNLEASH_TOGGLES.SaveProgress);
  const isSwitchEnabled = useFlag(UNLEASH_TOGGLES.Switch);
  const { handleOnClick, handleGridRowsChange } = useSwitchPage(
    dispatch,
    gridRef,
    setShowValidationErrorMessage,
    setValidationErrorMessage,
    setIsLoading,
  );
  const okModalButtons = [
    <ButtonPrimary key="OK" buttonText="OK" link="/" dataTestid="btn-ok" />,
  ];

  useEffect(() => {
    handleGenerateProposalStatus(
      generateSwitchProposalLoadingStatus.loading,
      setIsLoading,
      isSaveProgressEnabled,
      generateProposalErrorModalId,
      dispatch,
    );
  }, [
    generateSwitchProposalLoadingStatus.loading,
    dispatch,
    isSaveProgressEnabled,
  ]);

  useEffect(() => {
    handleGridRowsChange(applySwitchLoadingStatus.loading);
  }, [applySwitchLoadingStatus, handleGridRowsChange]);

  return (
    <div
      style={{
        height: '100%',
        flexDirection: 'column',
        display: 'flex',
        gap: '8px',
      }}
    >
      {isSwitchEnabled && (
        <>
          <IressRow>
            <IressCol>
              <SwitchPageTitle />
            </IressCol>
            <IressCol>
              <CtaPanel
                selectedRowCount={proposalsCount.toString()}
                totalRowCount={totalRowsCount.toString()}
                selectedLevel="account"
                showErrorMessage={showValidationErrorMessage}
                errorMessage={validationErrorMessage}
              >
                {isSaveProgressEnabled && (
                  <ButtonExit
                    onClick={() => showModal(SAVE_SESSION_MODAL_ID, true)}
                  />
                )}
                <ButtonPreviousStep
                  onClick={() => showModal(RETURN_MODAL_ID, true)}
                />
                <IressButton
                  key="generateProposals"
                  mode={IressButton.Mode.Primary}
                  type={IressButton.Type.Button}
                  data-testid="primary-btn"
                  onClick={handleOnClick}
                  loading={isLoading}
                >
                  Generate Proposals
                </IressButton>
              </CtaPanel>
            </IressCol>
          </IressRow>
          <IressPanel
            padding={IressPanel.Padding.Sm}
            background={IressPanel.Background.Default}
          >
            <IressStack gutter={IressStack.Gutter.Md}>
              <SwitchForm gridRef={gridRef} />
            </IressStack>
          </IressPanel>
          <Modal
            id={generateProposalErrorModalId}
            dataTestId={generateProposalErrorModalId}
            title="Something went wrong"
            message="Your progress has been saved. Please try again later."
            buttons={okModalButtons}
          />
          <SwitchGrid
            gridRef={gridRef}
            setProposalsCount={setProposalsCount}
            setTotalRowsCount={setTotalRowsCount}
            setShowValidationErrorMessage={setShowValidationErrorMessage}
          />
        </>
      )}
    </div>
  );
}
export default SwitchPage;
