export function isNumeric(str: unknown) {
  if (typeof str === 'number') return true;
  if (typeof str !== 'string') return false;
  return (
    // add an additional check to ensure the string is not a valid number
    !Number.isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !Number.isNaN(Number.parseFloat(str)) &&
    !Number.isNaN(Number(str))
  ); // ...and ensure strings of whitespace fail
}

export const assignZeroIfNullOrUndefined = (
  value: string | undefined | null,
): string => {
  // return '0' if value is null, undefined, or an empty string
  return value?.trim() === '' || value === null || value === undefined
    ? '0'
    : value;
};
