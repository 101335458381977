import {
  setProposals,
  setSelectedProposals,
  updatePositionSelection,
  updateProposal,
} from '@app/features/AccountGroups';
import { saveProgress } from '@app/services/progress';
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { RootState } from './store';
import { modifyStateForSavingProgress } from '@app/utils/modifiers.utils';
import { DEBOUNCE_TIME } from '@app/utils/constants';
import { deleteSavedProgressThunk } from '@app/features/SavedProgress';
import {
  createSwitchInstructionThunk,
  setSelectedSwitches,
  setSwitchProposals,
} from '@app/features/Switch';

const actionsListenedTo = isAnyOf(
  updatePositionSelection,
  setProposals,
  setSelectedProposals,
  updateProposal,
  deleteSavedProgressThunk.pending,
  setSwitchProposals,
  setSelectedSwitches,
  createSwitchInstructionThunk.fulfilled,
);

export const saveProgressListenerMiddleware =
  createListenerMiddleware<RootState>();

let saveTimer: ReturnType<typeof setTimeout> | undefined;
export function saveProgressDebounce(
  state: RootState,
  isDeleteAction: boolean = false,
) {
  if (saveTimer) {
    clearTimeout(saveTimer);
  }
  if (isDeleteAction) return;
  saveTimer = setTimeout(() => {
    const newState = modifyStateForSavingProgress(state);
    void saveProgress(newState).then(() => {
      console.log('Progress saved');
    });
  }, DEBOUNCE_TIME);
}

saveProgressListenerMiddleware.startListening({
  matcher: actionsListenedTo,
  effect: (action, listenerApi) => {
    const state = listenerApi.getState();
    //Check action type is deleteProgressThunk.pending
    const isDeleteAction =
      action.type === deleteSavedProgressThunk.pending.type;
    saveProgressDebounce(state, isDeleteAction);
  },
});
