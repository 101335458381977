import { useAppDispatch } from '@app/app/hooks';
import {
  setSwitchToSecurities,
  setInputtedCurrentHolding,
  setIsAdditionalSwitchVisible,
  SwitchSecurityState,
  fetchSwitchToSecurityMarketPrice,
  setSwitchToSecurityPercentage,
} from '@app/features/Switch';
import { SharedSecurityDetailsResponse } from '@bsa/shared-types';
import { FormFieldStatus, InputKeyboardEvent } from '@iress/components-react';
import { useCallback } from 'react';

interface UseSwitchEventHandlersParams {
  switchToSecurities: SwitchSecurityState[];
  availableSecurities: SharedSecurityDetailsResponse[];
}

export const useSwitchEventHandlers = ({
  switchToSecurities,
  availableSecurities,
}: UseSwitchEventHandlersParams) => {
  const dispatch = useAppDispatch();

  const handleCurrentHoldingChange = useCallback(
    (event: InputKeyboardEvent) => {
      dispatch(setInputtedCurrentHolding(event.target.value));
    },
    [dispatch],
  );

  const handleSwitchSecurityChange = useCallback(
    (index: number, value: string | number | string[]) => {
      const selectedSwitchToSecurity = availableSecurities.find(
        (availableSecurity) => availableSecurity.securityId === Number(value),
      );

      if (selectedSwitchToSecurity) {
        void dispatch(
          fetchSwitchToSecurityMarketPrice({
            index,
            updatedSwitchToSecurity: selectedSwitchToSecurity,
          }),
        );
      }
    },
    [availableSecurities, dispatch],
  );

  const handleFirstSwitchSecurityChange = useCallback(
    ({ value }: FormFieldStatus<HTMLElement>) => {
      handleSwitchSecurityChange(0, value);
    },
    [handleSwitchSecurityChange],
  );

  const handleSecondSwitchSecurityChange = useCallback(
    ({ value }: FormFieldStatus<HTMLElement>) => {
      handleSwitchSecurityChange(1, value);
    },
    [handleSwitchSecurityChange],
  );

  const handleSwitchPercentageChange = useCallback(
    (index: number, event: InputKeyboardEvent) => {
      dispatch(
        setSwitchToSecurityPercentage({
          index,
          percentage: event.target.value,
        }),
      );
    },
    [dispatch],
  );

  const handleFirstSwitchPercentageChange = useCallback(
    (event: InputKeyboardEvent) => {
      handleSwitchPercentageChange(0, event);
    },
    [handleSwitchPercentageChange],
  );

  const handleSecondSwitchPercentageChange = useCallback(
    (event: InputKeyboardEvent) => {
      handleSwitchPercentageChange(1, event);
    },
    [handleSwitchPercentageChange],
  );

  const handleAddAnotherClick = useCallback(
    () => dispatch(setIsAdditionalSwitchVisible(false)),
    [dispatch],
  );

  const handleDeleteSecurityClick = useCallback(() => {
    dispatch(setIsAdditionalSwitchVisible(true));
    const updatedSwitchToSecurities = [...switchToSecurities];
    updatedSwitchToSecurities.splice(1, 1);
    dispatch(setSwitchToSecurities(updatedSwitchToSecurities));
  }, [dispatch, switchToSecurities]);

  return {
    handleAddAnotherClick,
    handleCurrentHoldingChange,
    handleDeleteSecurityClick,
    handleFirstSwitchPercentageChange,
    handleFirstSwitchSecurityChange,
    handleSecondSwitchPercentageChange,
    handleSecondSwitchSecurityChange,
  };
};
